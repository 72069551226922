var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card text-center" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "avatar-sm mx-auto mb-4" }, [
        _c(
          "span",
          {
            staticClass:
              "avatar-title rounded-circle bg-soft bg-primary text-white font-size-16"
          },
          [
            _vm._v(
              _vm._s(_vm.user.first_name[0]) +
                "." +
                _vm._s(_vm.user.last_name[0])
            )
          ]
        )
      ]),
      _c("h5", { staticClass: "font-size-15 mb-1" }, [
        _c(
          "a",
          { staticClass: "text-dark", attrs: { href: "javascript: void(0);" } },
          [
            _vm._v(
              _vm._s(_vm.user.first_name) + " " + _vm._s(_vm.user.last_name)
            )
          ]
        )
      ]),
      _c("p", { staticClass: "text-muted" }, [
        _vm._v(_vm._s(_vm.user.role.name))
      ]),
      _vm._m(0)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "a",
        {
          staticClass: "badge bg-primary font-size-11 m-1",
          attrs: { href: "javascript: void(0);" }
        },
        [_vm._v("SONATRACH-DP IRARA")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }