var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-responsive" }, [
    _c(
      "table",
      {
        staticClass:
          "table project-list-table table-nowrap table-centered table-borderless"
      },
      [
        _vm._m(0),
        _c(
          "tbody",
          _vm._l(_vm.camps, function(camp, index) {
            return _c("tr", { key: index }, [
              _c("td", [_vm._v(_vm._s(index + 1))]),
              _c("td", [
                _c("h5", { staticClass: "text-truncate font-size-14" }, [
                  _c(
                    "a",
                    {
                      staticClass: "text-dark",
                      attrs: { href: "javascript: void(0);" }
                    },
                    [_vm._v(_vm._s(camp.name))]
                  )
                ]),
                _c("p", { staticClass: "text-muted mb-0" }, [
                  _vm._v(" " + _vm._s(camp.address) + " ")
                ])
              ]),
              _c("td", [
                _c("span", { staticClass: "badge bg-success" }, [
                  _vm._v(_vm._s(camp.data.currentOccupationRate))
                ])
              ]),
              _c("td", [
                _c("span", { staticClass: "badge bg-success" }, [
                  _vm._v(_vm._s(camp.data.currentAvailabilityRate))
                ])
              ]),
              _c("td", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function($event) {
                        return _vm.onSingleCampDetailsDisplay(camp)
                      }
                    }
                  },
                  [_vm._v("Détails")]
                )
              ])
            ])
          }),
          0
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "100px" }, attrs: { scope: "col" } }, [
          _vm._v("ID")
        ]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Site")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Taux d'occupation")]),
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("Taux de disponibilité")
        ]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Consulter")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }