<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import userProfil from "./components/core/userProfil";
import campsOverview from "./components/core/campsOverview";
import VueWeather from "vue-weather-widget";
import { mapGetters } from 'vuex';
import { gsap } from "gsap";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    // userProfil,
    // campsOverview,
    // VueWeather,
  },
  computed: {
    ...mapGetters({
      authenticated: 'apiAuth/authenticated',
      user: 'apiAuth/user',
    })
  }, 
  data() {
    return {
      title: "Vue 360°",
      tl:gsap.timeline(),
      open:false,
      base: {
        allowedCamps: [],
      },
      items: [
        {
          text: "Vue 360°",
          href: "/",
        },
      ],
    };
  },
  mounted(){
    
  },
  watch:{
    open:{
      handler(val){
        if(val) this.caculatePositions(this.user.campsData.length,150,50)
        else this.tl.reverse();
      }
    }
  },
  methods:{
    toRadians (angle) {
        return angle * (Math.PI / 180)
    },
    x (radius,angle,center) {
        let x = (radius * Math.cos(this.toRadians(angle)));
        if (x<0) return (-1*center)+x;
        else return center + x;
      },
    y (radius,angle) {
      return radius * Math.sin(this.toRadians(angle));
    },
    caculatePositions(length,radius,center){
      var angle = 360/length;
      var angles=[];
      var positions=[];
      for (let index = 0; index < length; index++) {
        angles.push(angle * index);
        positions.push({x:this.x(radius,(angle * index),center),y:this.y(radius,(angle * index))})
      }

      // this.tl = gsap.timeline();
      if(positions.length == 1) this.tl.to("#s-circle0",{x:positions[0].x,y:positions[0].y,duration:0.3,opacity:1,ease: "power1.out"})
      else if(positions.length > 1){
          positions.forEach((element,i) => {
            this.tl.to(`#s-circle${i}`,{x:element.x,y:element.y,duration:0.1,opacity:1,ease: "power1.out"})
          })
      }

      this.tl.play();
    },

    onSingleCampDetailsDisplay(camp){
        // this.$toast.info("TABLEAU DE BORD EN COURS DE PREPARATION");
        this.$router.push({ name: "dashboard.byCamp", params: { campId: camp.uuid } });
    }

  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- <div class="row">
      <div class="col-md-3">
        <div class="row">
          <div class="col-md-12">
            <userProfil :user="user"/>
          </div>
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <VueWeather
                  api-key="42530e12e6dbfd2df04c188448b9d8f7"
                  units="uk"
                  language="fr"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-9">
        <campsOverview :camps="user.campsData"/>
      </div>
    </div> -->
    
    <div class="place">
      <div class="circle shadow-lg">
        <img
            src="@/assets/images/sonatrach.png"
            alt=""
            height="150"
            @click="open=!open"
          />
          <span @click="open=!open" class="text-center">SH-DP <br> HASSI MESSAOUD</span>
      </div>
      <div v-for="(camp,i) in user.campsData" :key="i" :id="'s-circle'+i" class="small-circle text-center shadow-lg" @click="onSingleCampDetailsDisplay(camp)">
        <!-- <img
            src="@/assets/images/base/ops/hosting/base.png"
            alt=""
            height="50"
            @click="onSingleCampDetailsDisplay(camp)"
          /> -->
          <span>{{ camp.name }}</span>
          
      </div>
    </div>
  </Layout>
</template>
<style scoped>
  .place{
    position: absolute;
    top:35%;
    left:41%;
  }

  .circle{
    position: relative;
    width: 200px;
    height: 200px;
    top: 35px;
    left:45px;
    border-radius: 50%;
    background: white;
    z-index:999 !important;
  }

  .circle img {
    position: relative;
    top:25px;
    left:31px;
    width: 135px;
    cursor: pointer;
    opacity: 0.5;
  }

  .circle span {
    position: absolute;
    top:33%;
    left:8px;
    font-size: 21px;
    font-weight: bold;
    cursor: pointer;
  }

  .small-circle{
    position: absolute;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border:solid 2px;
    top:100px;
    left:100px;
    background: white;
    opacity: 0;
    z-index:99;
    cursor: pointer;
    align-content: center;
    align-items: center;
  }

  .small-circle span{
    position: relative;
    top:32px;
    font-weight: bold;
  }

  .small-circle img{
    position: relative;
    width: 50px;
    cursor: pointer;
  }
</style>