var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "div",
        { staticClass: "place" },
        [
          _c("div", { staticClass: "circle shadow-lg" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/sonatrach.png"),
                alt: "",
                height: "150"
              },
              on: {
                click: function($event) {
                  _vm.open = !_vm.open
                }
              }
            }),
            _c(
              "span",
              {
                staticClass: "text-center",
                on: {
                  click: function($event) {
                    _vm.open = !_vm.open
                  }
                }
              },
              [_vm._v("SH-DP "), _c("br"), _vm._v(" HASSI MESSAOUD")]
            )
          ]),
          _vm._l(_vm.user.campsData, function(camp, i) {
            return _c(
              "div",
              {
                key: i,
                staticClass: "small-circle text-center shadow-lg",
                attrs: { id: "s-circle" + i },
                on: {
                  click: function($event) {
                    return _vm.onSingleCampDetailsDisplay(camp)
                  }
                }
              },
              [_c("span", [_vm._v(_vm._s(camp.name))])]
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }